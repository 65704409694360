import { reduxRequest } from "../../../../common/utils/reduxRequest";
import { makeFilterString } from "../../utils/Helper";
import {
  CitiesLoading,
  CreateCitySuccess,
  DeleteCitySuccess,
  GetCitiesSuccess,
  GetCityDetailsSuccess,
  UpdateCitySuccess,
} from "./Action";

export const getCitiesRequest = () => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "list",
    successFunction: GetCitiesSuccess,
    url: `/admin/cities`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    successFunction: CreateCitySuccess,
    loadingType: "create",
    action: variables?.action,
    url: `admin/cities`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCityDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "get",
    successFunction: GetCityDetailsSuccess,
    action: variables?.action,
    url: `admin/cities/${variables?.id}`,
    method: "get",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

// export const updateCityRequest = ({ ...variables }) => {
//   const requestData = {
//     isLoading: CitiesLoading,
//     successFunction: UpdateCitySuccess,
//     loadingType: "update",
//     action: variables?.action,
//     url: `/admin/cities/${variables?.id}`,
//     data: variables?.body,
//     method: "put",
//   };

//   return async (dispatch) => {
//     reduxRequest({
//       dispatch,
//       ...requestData,
//     });
//   };
// };

export const updateCityRequest = ({ ...variables }) => {
  console.log(variables);
  const requestData = {
    isLoading: CitiesLoading,
    successFunction: UpdateCitySuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `admin/cities/${variables?.id}`,
    method: "put",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    successFunction: DeleteCitySuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `admin/cities/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
