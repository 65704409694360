import Types from "./Types";

export const GetPatientsSuccess = (payload) => ({
  type: Types.GET_PATIENTS_SUCCESS,
  payload,
});

export const PatientsLoading = (payload) => ({
  type: Types.PATIANTS_LOADING,
  payload,
});

export const GetSuspendedPatientsSuccess = (payload, data) => ({
  type: Types.GET_SUSPENDED_PATIENTS_SUCCESS,
  payload: payload,
});

export const SuspendPatientSuccess = (payload, data) => ({
  type: Types.SUSPEND_PATIENT_SUCCESS,
  payload: { payload, data },
});
