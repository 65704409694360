import React, { useState, useEffect } from "react";
import { Button, Input, Stack, Text, VStack } from "@chakra-ui/react";
import SelectComponent from "./selectComponent";

const DataFiltration = ({ filterList }) => {
  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setReset(false);
      }, 500);
    }
  }, [reset]);

  return (
    <Stack flexDirection="row" flexWrap="wrap" gap={2} alignItems="center">
      {!reset &&
        filterList?.map((list, index) => {
          return list?.type == "select" ? (
            <VStack alignItems="start">
              <Text fontSize="18px" fontWeight="500">
                {list?.label}
              </Text>
              <SelectComponent
                key={index}
                options={list?.options}
                onChange={list?.onChange}
                name={list?.name}
              />
            </VStack>
          ) : list?.type == "button" ? (
            <Button
              onClick={() => {
                setReset(true);
                list?.onClick();
              }}
              mt={8}
            >
              {list?.label}
            </Button>
          ) : (
            <VStack alignItems="start">
              <Text fontSize="18px" fontWeight="500">
                {list?.label}
              </Text>
              <Input onChange={list?.onChange} />
            </VStack>
          );
        })}
    </Stack>
  );
};

export default DataFiltration;

// <Stack
//   key={index}
//   flexDirection="row"
//   bgcolor="white"
//   borderRadius="10px"
//   width="20%"
// >

//   <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
//     <SearchIcon />
//   </IconButton>
//   <InputBase
//     sx={{ ml: 1, width: "100%" }}
//     placeholder={list?.placeholder}
//     type={"text"}
//     size="md"
//     onChange={(event) => setData({ event, list })}
//   />
// </Stack>
