import { Box, Textarea, Text } from "@chakra-ui/react";
import React from "react";
import CFormErrors from "./CFormErrors";

const CTextArea = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  colSpan,
  labelStyle,
}) => {
  const multinames = name?.split(".")?.map((data) => data);
  const value =
    multinames?.length == 2
      ? formik?.values?.[multinames[0]]?.[multinames[1]]
        ? formik?.values?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik?.values[name];
  const errorsMsg =
    multinames?.length == 2
      ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        ? formik?.errors?.[multinames[0]]?.[multinames[1]]
        : ""
      : formik.errors[name];

  return (
    <Box mb="5">
      {label && (
        <Text
          as="span"
          sx={{ color: "#797979", fontSize: "18px", fontFamily: "Jost", mb: 3 }}
        >
          {label}
        </Text>
      )}
      <Box>
        <Textarea
          placeholder={placeholder}
          type={type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={value}
          name={name}
          multiline
          rows={colSpan}
          fullWidth
          sx={{
            backgroundColor: "#FBFBFB",
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
            },
          }}
        />
        <CFormErrors errorsMsg={errorsMsg} />
      </Box>
    </Box>
  );
};

export default CTextArea;
