import React, { useRef } from "react";
import FormIndex from "../Forms/main";
import { Box, Button } from "@chakra-ui/react";
import FormHeader from "../Forms/main/formHeader";
const FormUse = ({
  formStructure = () => {},
  handleLanguage,
  className,
  formik,
  handleModelClose,
  form,
  children,
}) => {
  const refsubmit = useRef();

  return (
    <Box>
      {/* {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />} */}
      {form && (
        <FormHeader
          formikData={formik}
          form={form}
          handleModelClose={handleModelClose}
          refsubmit={refsubmit}
        />
      )}

      {handleLanguage}
      <FormIndex
        formStructures={formStructure()}
        formik={formik}
        className={className}
        refsubmit={refsubmit}
      >
        {children}
        <Button
          // sx={{ visibility: "none" }}
          ref={refsubmit}
          type="submit"
        ></Button>
      </FormIndex>
    </Box>
  );
};

export default FormUse;
