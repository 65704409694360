import { Stack, Input, Text } from "@chakra-ui/react";
import React from "react";
import CFormErrors from "./CFormErrors";

const CInput = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  ...rest
}) => {
  const value = formik?.values[name];
  const errorsMsg = formik.errors[name];
  return (
    <Stack>
      <Text
        as="span"
        sx={{
          color: "#797979",
          fontSize: "18px",
          fontFamily: "Jost",
          mb: 2,
        }}
      >
        {label}
      </Text>
      <Input
        id={name}
        type={type}
        label={label}
        placeholder={placeholder}
        onChange={formik?.handleChange}
        value={value || ""}
        onBlur={formik?.handleBlur}
        name={name}
        {...rest}
      />
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CInput;
