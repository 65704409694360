/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  areas: [],
  area: {},
  count: 0,
  load: null,
};

export function areasReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.AREAS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_AREAS_SUCCESS: {
      return {
        ...state,
        areas: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.DELETE_AREA_SUCCESS: {
      const id = payload?.data;
      const newData = state.areas?.filter((data) => data?.id != id);
      return {
        ...state,
        areas: newData,
      };
    }
    case Types.CREATE_AREA_SUCCESS: {
      return {
        ...state,
        areas: [...state?.areas, payload?.data],
      };
    }

    case Types.GET_AREA_DETAILS_SUCCESS: {
      return {
        ...state,
        area: payload?.data,
      };
    }
    case Types.UPDATE_AREA_SUCCESS: {
      console.log(payload);
      const newData = payload?.data;
      let newState = state.areas;
      let dataIndx;
      state.areas.map((data, index) => {
        if (data?.id == newData?.id) dataIndx = index;
      });
      newState[dataIndx] = newData;
      return {
        ...state,
        areas: newState,
      };
    }

    default: {
      return state;
    }
  }
}
