import Types from "./Types";

export const GetEntitiesSuccess = (payload) => ({
  type: Types.GET_ENTITIES_SUCCESS,
  payload,
});

export const EntitiesLoading = (payload) => ({
  type: Types.ENTITIES_LOADING,
  payload,
});

export const GetSuspendedEntitiesSuccess = (payload, data) => ({
  type: Types.GET_SUSPENDED_ENTITIES_SUCCESS,
  payload: payload,
});

export const GetUnapprovedEntitiesSuccess = (payload, data) => ({
  type: Types.GET_UNAPPROVED_ENTITIES_SUCCESS,
  payload: payload,
});

export const GetEntitieDetailsSuccess = (payload) => ({
  type: Types.GET_ENTITIE_DETAILS_SUCCESS,
  payload,
});

export const SuspendEntitieSuccess = (payload, data) => ({
  type: Types.SUSPEND_ENTITIE_SUCCESS,
  payload: { payload, data },
});

export const ApproveEntitieSuccess = (payload, data) => ({
  type: Types.APPROVE_ENTITIE_SUCCESS,
  payload: { payload, data },
});
