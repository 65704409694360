import { combineReducers } from "redux";

import authReducer from "./modules/auth/Reducer";
import { citiesReducer } from "./modules/cities/cities/Reducer";
import { areasReducer } from "./modules/cities/areas/Reducer";
import { specialtiesReducer } from "./modules/specialty/specialty/Reducer";
import { subspecialtiesReducer } from "./modules/specialty/subspecialty/Reducer";
import { doctorsReducer } from "./modules/doctors/Reducer";
import { patientsReducer } from "./modules/patients/Reducer";
import modalReducer from "./modules/modal/Reducer";
import { entitiesReducer } from "./modules/entities/Reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  cities: citiesReducer,
  areas: areasReducer,
  specialties: specialtiesReducer,
  subspecialties: subspecialtiesReducer,
  doctors: doctorsReducer,
  patients: patientsReducer,
  models: modalReducer,
  entities: entitiesReducer,
});

export default rootReducer;
