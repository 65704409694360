import {
  Avatar,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

const Header = () => {
  const navigate = useNavigate();
  const token = Cookies.get("Token");
  return (
    <Stack
      width="100%"
      bgcolor="white"
      p={2}
      direction="row"
      justifyContent={!token ? "space-between" : "end"}
      fontWeight="500"
    >
      {!token ? (
        <>
          <Button
            bg="#FF9138"
            color="white"
            w="150px"
            onClick={() => navigate("/login")}
          >
            LOGIN
          </Button>
        </>
      ) : (
        <Menu>
          <MenuButton as={Button} alignSelf="end">
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ borderRadius: "50%" }}
            />
          </MenuButton>
          <MenuList>
            <MenuItem
              minH="48px"
              onClick={() => {
                Cookies.remove("Token");
                Cookies.remove("Name");
                Cookies.remove("Phone");
                Cookies.remove("Role");

                window.location.replace("/");
              }}
            >
              logout
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Stack>
  );
};

export default Header;
