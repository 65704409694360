import { Box, Button, Image, Input, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { structure } from "./structure";
import { useDispatch } from "react-redux";
import { loginRequest } from "../../services/modules/auth";
import FormIndex from "../../common/shared/Forms/main";
import Logo from "../../assets/logo.png";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append("email", values?.email);
      formdata.append("password", values?.password);
      const requestData = {
        action: (data) => {
          if (
            data.data?.access_token &&
            data.data?.role?.[0]?.name.toLowerCase().includes("admin")
          ) {
            Cookies.set("Token", data.data?.access_token);
            Cookies.set("Name", data.data?.full_name);
            Cookies.set("Phone", data.data?.phone);
            Cookies.set("Role", data.data?.role?.[0]?.name);

            window.location.replace("/specialties");
          } else {
            toast.error("User not found");
          }
        },
        body: formdata,
      };
      dispatch(loginRequest(requestData));
    },
  });
  return (
    <VStack
      bgColor="#E9E9E9"
      minH="100vh"
      justifyContent="center"
      alignItems="center"
      gap="30px"
    >
      <Box
        width={{ base: "100%", md: "45%" }}
        bgColor="white"
        boxShadow="0px 2px 4px 0px #00000040"
        borderRadius="16px"
        p={4}
        alignContent="center"
        justifyContent="center"
      >
        <Image src={Logo} width="50%" m="auto" />

        <FormIndex formStructures={structure()} formik={formik} width="100%">
          <Button
            type="submit"
            variant="solid"
            width={{ base: "100%", md: "50%" }}
            alignSelf="center"
            mt={8}
          >
            Log in
          </Button>
        </FormIndex>
      </Box>
    </VStack>
  );
};

export default Login;
