import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = (cities, id) => [
  {
    name: "area_name",
    kind: "input",
    type: "text",
    label: "Area name",
    width: "80%",
    placeholder: "Write the area name",
  },
  {
    name: "name_ar",
    kind: "input",
    type: "text",
    label: "Area Arabic name",
    width: "80%",
    placeholder: "Write the area name",
  },
  !id && {
    name: "city_id",
    kind: "select",
    type: "text",
    label: "City",
    width: "80%",
    optionsName: "city_name",
    options: cities,
  },
];

export const validationSchema = () =>
  Yup.object({
    area_name: Yup.string().required("Required"),
  });
