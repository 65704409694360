import Types from "./Types";

export const GetAreasSuccess = (payload) => ({
  type: Types.GET_AREAS_SUCCESS,
  payload,
});

export const AreasLoading = (payload) => ({
  type: Types.AREAS_LOADING,
  payload,
});

export const GetAreaDetailsSuccess = (payload) => ({
  type: Types.GET_AREA_DETAILS_SUCCESS,
  payload,
});

export const DeleteAreaSuccess = (payload, data) => ({
  type: Types.DELETE_AREA_SUCCESS,
  payload: { payload, data },
});

export const UpdateAreaSuccess = (payload, data) => ({
  type: Types.UPDATE_AREA_SUCCESS,
  payload: payload,
  data: data,
});
export const CreateAreaSuccess = (payload, data) => ({
  type: Types.CREATE_AREA_SUCCESS,
  payload: payload,
  data: data,
});
