import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = () => [
  {
    name: "city_name",
    kind: "input",
    type: "text",
    label: "City name",
    width: "80%",
    placeholder: "Write the city name",
  },
];

export const validationSchema = () =>
  Yup.object({
    city_name: Yup.string().required("Required"),
  });
