import React, { useState } from "react";
import CCrud from "../../components/molecules/crud";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { getCitiesRequest } from "../../services/modules/cities/cities";
import { getSubspecialtiesRequest } from "../../services/modules/specialty/subspecialty";
import { getSpecialtiesRequest } from "../../services/modules/specialty/specialty";
import {
  getDoctorsRequest,
  suspendDoctorRequest,
} from "../../services/modules/doctors";
import { getAreasRequest } from "../../services/modules/cities/areas";
import QrCode from "../../components/molecules/doctors/models/qr-code";
import {
  isOpenDoctorDetailsgModel,
  isOpenDoctorQrCode,
} from "../../services/modules/modal/Actions";
import DoctorDetails from "../../components/molecules/doctors/models/details";
import DoctorData from "../../components/molecules/doctors/models/data";

const Doctors = ({ paginationPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.doctors.doctors);
  const count = useSelector((state) => state.doctors.count);
  const load = useSelector((state) => state.doctors.load);
  const specialties = useSelector((state) => state.specialties.specialties);
  const subspecialties = useSelector(
    (state) => state.subspecialties.subspecialties
  );
  const cities = useSelector((state) => state.cities.cities);
  const areas = useSelector((state) => state.areas.areas);

  console.log(list);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async () => {
    const requestData = {
      params: {
        sort_by: "id",
        ...filterObj,
      },
      id,
    };
    dispatch(getDoctorsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      title: data?.doctor_details?.doctor_title?.title_ar,
      specialty: data?.doctor_details?.specialty?.name,
      max_patient: data?.doctor_details?.Max_patient,
      approved: (
        <Text color={data?.is_approved ? "green" : "red"}>
          {data?.is_approved ? "true" : "false"}
        </Text>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  console.log(selectedRow);
  const filterList = [
    {
      type: "search",
      label: "Name",
      onChange: (e) => {
        console.log(e);
        setFilterObj({ ...filterObj, name: e?.target?.value });
      },
    },
    {
      type: "select",

      name: "name",
      label: "Specialties",
      onChange: (value) => {
        console.log(value);
        setFilterObj({ ...filterObj, specialty_id: value?.value });
      },
      options: specialties,
    },
    {
      type: "select",

      name: "name",
      label: "Sub Specialties",
      onChange: (value) => {
        setFilterObj({ ...filterObj, sub_specialty_id: value?.value });
      },
      options: subspecialties,
    },
    {
      type: "select",
      name: "city_name",
      label: "City",
      onChange: (value) => {
        setFilterObj({ ...filterObj, city_id: value?.value });
      },
      options: cities,
    },
    {
      type: "select",

      name: "area_name",
      label: "Areas",
      onChange: (value) => {
        setFilterObj({ ...filterObj, area_id: value?.value });
      },
      options: areas,
    },
    {
      type: "button",
      label: "Reset",
      onClick: () => {
        setFilterObj({});
      },
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(isOpenDoctorDetailsgModel());
        },
        text: "Details",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(
            suspendDoctorRequest({
              id: data?.id,
            })
          );
        },
        text: "Suspend",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(isOpenDoctorQrCode());
        },
        text: "Qr codes",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    dispatch(getCitiesRequest());
    dispatch(getSpecialtiesRequest());
  }, []);
  useEffect(() => {
    const timeId = setTimeout(() => {
      getList({ params: filterObj });
    }, 500);
    return () => clearTimeout(timeId);
  }, [filterObj]);

  useEffect(() => {
    if (filterObj?.city_id)
      dispatch(getAreasRequest({ id: filterObj?.city_id }));
    if (filterObj?.specialty_id)
      dispatch(getSubspecialtiesRequest({ id: filterObj?.specialty_id }));
  }, [filterObj]);

  return (
    <Box w="100%">
      <CCrud
        table={{
          list: mappedData,
          listTitle: "Doctors",
          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "title",
              Header: "Doctor title",
              // sort: true,
            },
            {
              accessor: "full_name",
              Header: "Doctor name",
              // sort: true,
            },
            {
              accessor: "phone",
              Header: "Doctor phone",
              // sort: true,
            },
            {
              accessor: "approved",
              Header: "Is Approved",
              // sort: true,
            },
            {
              accessor: "specialty",
              Header: "Doctor specialty",
              // sort: true,
            },
            {
              accessor: "date_of_birth",
              Header: "Date of birth",
              // sort: true,
            },
            {
              accessor: "max_patient",
              Header: "Doctor Max patient",
              // sort: true,
            },

            { accessor: "Actions", Header: "Actions" },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by Sub specialties name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          filterList,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
      <QrCode data={selectedRow?.rowData} />
      <DoctorDetails data={selectedRow?.rowData} />
      <DoctorData data={selectedRow?.rowData} />
    </Box>
  );
};

export default Doctors;
