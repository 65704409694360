import { Grid } from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Footer = ({
  total_count,
  totalPage,
  size = "small",
  shape = "rounded",
  paginationPage,
  handlePageChange,
}) => {
  return (
    totalPage > 1 && (
      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        mb={7.5}
        mt={5}
        bgcolor="white"
      >
        {/* <Pagination
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: IoIosArrowBack, next: IoIosArrowForward }}
              {...item}
              color="secondary"
            />
          )}
          count={Math.ceil(total_count / 10)}
          sx={{ fontFamily: "Jost" }}
          size={size}
          // className="pagination"
          shape={shape}
          page={paginationPage}
          onChange={(e, data) => handlePageChange(data)}
        /> */}
      </Grid>
    )
  );
};

export default Footer;
