import { Box, Stack } from "@chakra-ui/react";
import React from "react";
import CInput from "./components/CInput";
import CPasswordInput from "./components/CTextArea";
import CTextArea from "./components/CTextArea";
import SelectOptions from "./components/CSelect";
import CCheckbox from "./components/CCheckbox";
import CUpload from "./components/CUpload";
import FormHead from "./FormHead/FormHead";
import CRadioBox from "./components/CRadioBox";
import CMultiUpload from "./components/CMultiupload";
import CMultiSelect from "./components/CMultiSelect";
import CPhoneInput from "./components/CPhoneInput";

const FormIndex = ({ formStructures, formik, children, className }) => {
  const renderInputs = (data, index) => {
    const generalProps = {
      ...data,
      formik: formik,
      colSpan: data?.colSpan,
      index: index,
    };
    if (data.kind === "input") return <CInput {...generalProps} />;
    if (data.kind === "passwordInput")
      return <CPasswordInput {...generalProps} />;
    if (data.kind === "textArea") return <CTextArea {...generalProps} />;
    if (data.kind === "select")
      return (
        <SelectOptions
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );

    if (data.kind === "multiSelect")
      return (
        <CMultiSelect
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );
    if (data.kind === "checkbox")
      return (
        <CCheckbox
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );
    if (data.kind === "phone")
      return (
        <CPhoneInput
          {...generalProps}
          changeAction={(value, country, e, formattedValue) => {
            formik.setValues({
              ...formik?.values,
              phone_number: value,
              country_code: `+${country?.dialCode}`,
            });
          }}
        />
      );
    if (data.kind === "upload")
      return (
        <CUpload
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );
    if (data.kind === "multiUpload")
      return (
        <CMultiUpload
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: value })
          }
        />
      );

    if (data.kind === "radioBox")
      return (
        <CRadioBox
          {...generalProps}
          changeAction={(value) =>
            formik.setValues({ ...formik?.values, [data?.name]: +value })
          }
        />
      );
  };
  return (
    <form
      onSubmit={formik?.handleSubmit}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Stack gap={2} width="100%" flexWrap="wrap" flexDirection="row">
        {formStructures?.map((data, index) => {
          return (
            <Box
              key={index}
              className={className}
              width={data?.width || "100%"}
              bgcolor="white"
              p={3}
            >
              <FormHead text={data?.head} />
              <Stack gap={3} width="100%" flexWrap="wrap" flexDirection="row">
                <Box
                  width="100%"
                  key={index}
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {renderInputs(data, index)}
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Stack>
      {children}
    </form>
  );
};

export default FormIndex;
