import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = (specialties, id) => [
  {
    name: "name",
    kind: "input",
    type: "text",
    label: "Sub specialty name",
    width: "80%",
    placeholder: "Write the Sub specialty name",
  },
  !id && {
    name: "specialty_id",
    kind: "select",
    type: "text",
    label: "Specialty",
    width: "80%",
    optionsName: "name",
    options: specialties,
  },
];

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required("Required"),
  });
