const Types = {
  //! SUBSPECIALTYS
  GET_PATIENTS_SUCCESS: "GET_PATIENTS_SUCCESS",
  PATIANTS_LOADING: "PATIANTS_LOADING",
  GET_SUSPENDED_PATIENTS_SUCCESS: "GET_SUSPENDED_PATIENTS_SUCCESS",
  GET_UNAPPROVED_PATIENTS_SUCCESS: "GET_UNAPPROVED_PATIENTS_SUCCESS",
  GET_PATIENT_DETAILS_SUCCESS: "GET_PATIENT_DETAILS_SUCCESS",
  SUSPEND_PATIENT_SUCCESS: "SUSPEND_PATIENT_SUCCESS",
  APPROVE_PATIENT_SUCCESS: "APPROVE_PATIENT_SUCCESS",
};

export default Types;
