import { reduxRequest } from "../../../../common/utils/reduxRequest";
import { makeFilterString } from "../../utils/Helper";
import {
  AreasLoading,
  CreateAreaSuccess,
  DeleteAreaSuccess,
  GetAreasSuccess,
  GetAreaDetailsSuccess,
  UpdateAreaSuccess,
} from "./Action";

export const getAreasRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AreasLoading,
    loadingType: "list",
    successFunction: GetAreasSuccess,
    url: `/admin/areas/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createAreaRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AreasLoading,
    successFunction: CreateAreaSuccess,
    loadingType: "create",
    action: variables?.action,
    url: `admin/areas`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getAreaDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AreasLoading,
    loadingType: "get",
    successFunction: GetAreaDetailsSuccess,
    action: variables?.action,
    url: `admin/areas/${variables?.id}`,
    method: "get",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateAreaRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AreasLoading,
    successFunction: UpdateAreaSuccess,
    loadingType: "update",
    action: variables?.action,
    url: `admin/areas/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteAreaRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: AreasLoading,
    successFunction: DeleteAreaSuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `admin/areas/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
