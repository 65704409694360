import { reduxRequest } from "../../../../common/utils/reduxRequest";
import { makeFilterString } from "../../utils/Helper";
import { GetSubspecialtiesSuccess } from "./Action";
import {
  SubspecialtiesLoading,
  CreateSubspecialtySuccess,
  DeleteSubspecialtySuccess,
  GetSubspecialtyDetailsSuccess,
  UpdateSubspecialtySuccess,
} from "./Action";

export const getSubspecialtiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SubspecialtiesLoading,
    loadingType: "list",
    successFunction: GetSubspecialtiesSuccess,
    url: `/admin/sub_specialties/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createSubspecialtyRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SubspecialtiesLoading,
    successFunction: CreateSubspecialtySuccess,
    loadingType: "create",
    action: variables?.action,
    url: `admin/sub_specialties`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getSubspecialtyDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SubspecialtiesLoading,
    loadingType: "get",
    successFunction: GetSubspecialtyDetailsSuccess,
    action: variables?.action,
    url: `admin/sub_specialties/${variables?.id}`,
    method: "get",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateSubspecialtyRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SubspecialtiesLoading,
    successFunction: UpdateSubspecialtySuccess,
    loadingType: "update",
    action: variables?.action,
    url: `admin/sub_specialties/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteSubspecialtyRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SubspecialtiesLoading,
    successFunction: DeleteSubspecialtySuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `admin/sub_specialties/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
