import { reduxRequest } from "../../../../common/utils/reduxRequest";
import { makeFilterString } from "../../utils/Helper";
import {
  SpecialtiesLoading,
  CreateSpecialtySuccess,
  DeleteSpecialtySuccess,
  GetSpecialtiesSuccess,
  GetSpecialtyDetailsSuccess,
  UpdateSpecialtySuccess,
} from "./Action";

export const getSpecialtiesRequest = () => {
  const requestData = {
    isLoading: SpecialtiesLoading,
    loadingType: "list",
    successFunction: GetSpecialtiesSuccess,
    url: `/admin/specialties`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createSpecialtyRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SpecialtiesLoading,
    successFunction: CreateSpecialtySuccess,
    loadingType: "create",
    action: variables?.action,
    url: `admin/specialties`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getSpecialtyDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SpecialtiesLoading,
    loadingType: "get",
    successFunction: GetSpecialtyDetailsSuccess,
    action: variables?.action,
    url: `admin/specialties/${variables?.id}`,
    method: "get",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateSpecialtyRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SpecialtiesLoading,
    successFunction: UpdateSpecialtySuccess,
    loadingType: "update",
    action: variables?.action,
    url: `admin/specialties/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteSpecialtyRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: SpecialtiesLoading,
    successFunction: DeleteSpecialtySuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `admin/specialties/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
