import React, { useState } from "react";
import CCrud from "../../../components/molecules/crud";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import {
  approveDoctorRequest,
  getUnApprovedVetDoctorsRequest,
} from "../../../services/modules/doctors";
import { toast } from "react-toastify";
import { isOpenDoctorDetailsgModel } from "../../../services/modules/modal/Actions";
import DoctorData from "../../../components/molecules/doctors/models/data";

const UnapprovedVetDoctors = ({ paginationPage }) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.doctors.unapprovedVetDoctors);
  const count = useSelector((state) => state.doctors.count);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [, setPage] = useState(1);
  const [, setFilterObj] = useState(null);
  console.log(list);
  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async () => {
    dispatch(getUnApprovedVetDoctorsRequest());
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      title: data?.doctor_details?.doctor_title?.title_ar,
      specialty: data?.doctor_details?.specialty?.name,
      max_patient: data?.doctor_details?.Max_patient,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(isOpenDoctorDetailsgModel());
        },
        text: "Details",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          const action = () => {
            toast.success("approved successufly");
            getList();
          };
          dispatch(
            approveDoctorRequest({
              id: data?.id,
              action,
            })
          );
        },
        text: "Approve",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    getList();
  }, []);

  return (
    <Box w="100%">
      <CCrud
        table={{
          list: mappedData,
          listTitle: "Unapproved Doctors",
          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "title",
              Header: "Doctor title",
              // sort: true,
            },
            {
              accessor: "full_name",
              Header: "Doctor name",
              // sort: true,
            },
            {
              accessor: "phone",
              Header: "Doctor phone",
              // sort: true,
            },

            {
              accessor: "specialty",
              Header: "Doctor specialty",
              // sort: true,
            },
            {
              accessor: "date_of_birth",
              Header: "Date of birth",
              // sort: true,
            },
            {
              accessor: "max_patient",
              Header: "Doctor Max patient",
              // sort: true,
            },

            { accessor: "Actions", Header: "Actions" },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by Sub specialties name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          filterList,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
      <DoctorData data={selectedRow?.rowData} />
    </Box>
  );
};

export default UnapprovedVetDoctors;
