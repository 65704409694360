import Types from "./Types";

export const isOpenPatientQrCode = () => ({
  type: Types.IS_OPEN_PATIENT_QR_CODE,
});

export const isOpenDoctorQrCode = (payload) => ({
  type: Types.IS_OPEN_DOCTOR_QR_CODE,
  payload,
});

export const isOpenAssignToFreelancer = (payload) => ({
  type: Types.IS_OPEN_ASSIGN_TO_FREELANCER,
  payload,
});

export const isOpenDriverDetails = () => ({
  type: Types.IS_OPEN_DRIVER_DETAILS,
});

export const isOpenViewComplain = (payload) => ({
  type: Types.IS_OPEN_VIEW_COMPLAIN,
  payload,
});

export const isOpenFollowesModel = (payload) => ({
  type: Types.IS_OPEN_FOLLOWES_MODEL,
  payload,
});

export const isOpenFollowingModel = (payload) => ({
  type: Types.IS_OPEN_FOLLOWING_MODEL,
  payload,
});

export const isOpenDoctorDetailsgModel = (payload) => ({
  type: Types.IS_OPEN_DOCTOR_DETAILS_MODEL,
  payload,
});

export const CloseSuccessModal = () => ({
  type: Types.CLOSE_SUCCESS_MODAL,
});
