import { reduxRequest } from "../../../common/utils/reduxRequest";
import {
  PatientsLoading,
  GetPatientsSuccess,
  GetSuspendedPatientsSuccess,
  SuspendPatientSuccess,
} from "./Action";

export const getSuspendedPatientsRequest = () => {
  const requestData = {
    isLoading: PatientsLoading,
    loadingType: "listSuspend",
    successFunction: GetSuspendedPatientsSuccess,
    url: `admin/patients/suspended`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getPatientsRequest = () => {
  const requestData = {
    isLoading: PatientsLoading,
    loadingType: "list",
    successFunction: GetPatientsSuccess,
    url: `admin/patients`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const suspendPatientRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: PatientsLoading,
    successFunction: SuspendPatientSuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `admin/patients/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
