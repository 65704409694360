import React from "react";
import Select from "react-select";

const SelectComponent = ({ options, onChange, name }) => {
  const customeOptions = options?.map((data) => {
    return {
      label: data?.[name],
      value: data?.id,
    };
  });
  return (
    <Select
      options={customeOptions}
      onChange={(event) => onChange(event)}
      width="25%"
    />
  );
};

export default SelectComponent;
