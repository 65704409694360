import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function DetailsList({ data }) {
  const backgroundGray = "red";
  const textGray = "blue";
  const labelColor = "green";
  return (
    <Box p="24px">
      <Stack direction="row" gap="8px" alignItems="center" mb="26px">
        {data?.icon && (
          <Stack
            justifyContent="center"
            alignItems="center"
            width="48px"
            height="48px"
            sx={{ borderRadius: "8px", bgcolor: backgroundGray }}
          >
            <Box component="img" src={data?.icon} />
          </Stack>
        )}

        <Text
          fontSize="26px"
          fontWeight="500"
          fontFamily="Jost"
          width="100%"
          color={textGray}
          width="100%"
        >
          {data?.head}
        </Text>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap="30px">
        {data?.details?.map((data) => (
          <Stack
            gap="12px"
            width={data?.width ? data?.width : "45% !important"}
            mr="20px"
          >
            <Text fontSize="16px" fontFamily="Jost" color={labelColor}>
              {data?.label}
            </Text>
            <Text
              fontSize="20px"
              fontFamily="Jost"
              color={data?.color || textGray}
            >
              {data?.name}
            </Text>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
