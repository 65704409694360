const Types = {
  //! SUBSPECIALTYS
  GET_SUBSPECIALTIES_SUCCESS: "GET_SUBSPECIALTIES_SUCCESS",
  GET_SUBSPECIALTY_DETAILS_SUCCESS: "GET_SUBSPECIALTY_DETAILS_SUCCESS",
  SUBSPECIALTIES_LOADING: "SUBSPECIALTIES_LOADING",
  DELETE_SUBSPECIALTY_SUCCESS: "DELETE_SUBSPECIALTY_SUCCESS",
  UPDATE_SUBSPECIALTY_SUCCESS: "UPDATE_SUBSPECIALTY_SUCCESS",
  CREATE_SUBSPECIALTY_SUCCESS: "CREATE_SUBSPECIALTY_SUCCESS",
};

export default Types;
