const Types = {
  //! SPECIALTYS
  GET_SPECIALTIES_SUCCESS: "GET_SPECIALTIES_SUCCESS",
  GET_SPECIALTY_DETAILS_SUCCESS: "GET_SPECIALTY_DETAILS_SUCCESS",
  SPECIALTIES_LOADING: "SPECIALTIES_LOADING",
  DELETE_SPECIALTY_SUCCESS: "DELETE_SPECIALTY_SUCCESS",
  UPDATE_SPECIALTY_SUCCESS: "UPDATE_SPECIALTY_SUCCESS",
  CREATE_SPECIALTY_SUCCESS: "CREATE_SPECIALTY_SUCCESS",
};

export default Types;
