/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  patients: [],
  suspendedPatients: [],
  count: 0,
  load: null,
};

export function patientsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.PATIANTS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_PATIENTS_SUCCESS: {
      return {
        ...state,
        patients: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.GET_SUSPENDED_PATIENTS_SUCCESS: {
      return {
        ...state,
        suspendedPatients: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.SUSPEND_PATIENT_SUCCESS: {
      const id = payload?.data;
      const newData = state.patients?.filter((data) => data?.id != id);
      return {
        ...state,
        patients: newData,
      };
    }

    default: {
      return state;
    }
  }
}
