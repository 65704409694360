import Types from "./Types";

const INIT_STATE = {
  openDoctorQrCode: false,
  openPatientQrCode: false,
  assignToFreelancer: false,
  driverDetails: false,
  viewComplain: false,
  followesModel: false,
  followingModel: false,
  doctorDetails: false,
  route: {},
  isOpen: false,
  message: null,
};

export default function modalReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.IS_OPEN_DOCTOR_QR_CODE: {
      return {
        ...state,
        openDoctorQrCode: !state.openDoctorQrCode,
      };
    }
    case Types.IS_OPEN_PATIENT_QR_CODE: {
      return {
        ...state,
        openPatientQrCode: !state.openPatientQrCode,
      };
    }
    case Types.IS_OPEN_ASSIGN_TO_FREELANCER: {
      return {
        ...state,
        assignToFreelancer: !state.assignToFreelancer,
        route: payload,
      };
    }
    case Types.IS_OPEN_DRIVER_DETAILS: {
      return {
        ...state,
        driverDetails: !state.driverDetails,
      };
    }
    case Types.IS_OPEN_VIEW_COMPLAIN: {
      return {
        ...state,
        viewComplain: !state.viewComplain,
      };
    }
    case Types.IS_OPEN_FOLLOWES_MODEL: {
      return {
        ...state,
        followesModel: !state.followesModel,
      };
    }
    case Types.IS_OPEN_FOLLOWING_MODEL: {
      return {
        ...state,
        followingModel: !state.followingModel,
      };
    }
    case Types.IS_OPEN_DOCTOR_DETAILS_MODEL: {
      return {
        ...state,
        doctorDetails: !state.doctorDetails,
      };
    }
    case Types.CLOSE_SUCCESS_MODAL: {
      return {
        ...state,
        modal: {
          isOpen: false,
          message: null,
        },
      };
    }
    default: {
      return state;
    }
  }
}
