import { reduxRequest } from "../../../common/utils/reduxRequest";
import { GetUnapprovedDoctorsSuccess } from "../doctors/Action";
import {
  ApproveEntitieSuccess,
  EntitiesLoading,
  GetEntitiesSuccess,
  GetUnapprovedEntitiesSuccess,
  SuspendEntitieSuccess,
} from "./Action";

export const getEntitiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: EntitiesLoading,
    loadingType: "list",
    successFunction: GetEntitiesSuccess,
    url: `/list/entities`,
    method: "get",
    data: variables?.params,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const approveEntityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: EntitiesLoading,
    successFunction: ApproveEntitieSuccess,
    loadingType: "approve",
    action: variables?.action,
    url: `/admin/entities/${variables?.id}`,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const suspendEntityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: EntitiesLoading,
    successFunction: SuspendEntitieSuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/entities/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const unapprovedEntitiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: EntitiesLoading,
    successFunction: GetUnapprovedEntitiesSuccess,
    loadingType: "getUnapproved",
    action: variables?.action,
    url: `/admin/entities/unapproved/${variables?.id}`,
    method: "get",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
