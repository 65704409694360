const Types = {
  //! SUBSPECIALTYS
  GET_ENTITIES_SUCCESS: "GET_ENTITIES_SUCCESS",
  ENTITIES_LOADING: "ENTITIES_LOADING",
  GET_SUSPENDED_ENTITIES_SUCCESS: "GET_SUSPENDED_ENTITIES_SUCCESS",
  GET_UNAPPROVED_ENTITIES_SUCCESS: "GET_UNAPPROVED_ENTITIES_SUCCESS",
  GET_ENTITIE_DETAILS_SUCCESS: "GET_ENTITIE_DETAILS_SUCCESS",
  SUSPEND_ENTITIE_SUCCESS: "SUSPEND_ENTITIE_SUCCESS",
  APPROVE_ENTITIE_SUCCESS: "APPROVE_ENTITIE_SUCCESS",
};

export default Types;
