import Types from "./Types";

export const GetSpecialtiesSuccess = (payload) => ({
  type: Types.GET_SPECIALTIES_SUCCESS,
  payload,
});

export const SpecialtiesLoading = (payload) => ({
  type: Types.SPECIALTIES_LOADING,
  payload,
});

export const GetSpecialtyDetailsSuccess = (payload) => ({
  type: Types.GET_SPECIALTY_DETAILS_SUCCESS,
  payload,
});

export const DeleteSpecialtySuccess = (payload, data) => ({
  type: Types.DELETE_SPECIALTY_SUCCESS,
  payload: { payload, data },
});

export const UpdateSpecialtySuccess = (payload, data) => ({
  type: Types.UPDATE_SPECIALTY_SUCCESS,
  payload: payload,
  data: data,
});
export const CreateSpecialtySuccess = (payload, data) => ({
  type: Types.CREATE_SPECIALTY_SUCCESS,
  payload: payload,
  data: data,
});
