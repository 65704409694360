/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  entities: [],
  unapprovedEntities: [],
  doctor: {},
  count: 0,
  load: null,
};

export function entitiesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.ENTITIES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_ENTITIES_SUCCESS: {
      return {
        ...state,
        entities: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.APPROVE_ENTITIE_SUCCESS: {
      const id = payload?.payload?.data?.id;
      const newData = state.unapprovedEntities?.filter(
        (data) => data?.id !== id
      );
      return {
        ...state,
        unapprovedEntities: newData,
      };
    }
    case Types.SUSPEND_ENTITIE_SUCCESS: {
      const id = payload?.payload?.data?.id;
      const newData = state.unapprovedEntities?.filter(
        (data) => data?.id !== id
      );
      return {
        ...state,
        unapprovedEntities: newData,
      };
    }
    case Types.GET_UNAPPROVED_ENTITIES_SUCCESS: {
      return {
        ...state,
        unapprovedEntities: payload?.data,
      };
    }

    default: {
      return state;
    }
  }
}
