import "./App.css";
import { Provider } from "react-redux";
import { ChakraBaseProvider } from "@chakra-ui/react";
import { theme } from "./common/styles/style.js";
import { router } from "./common/routers/index.jsx";
import { store } from "./services/store.js";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import setAuthToken from "./services/modules/utils/handel_api.js";
function App() {
  const token = Cookies.get("Token");
  setAuthToken(token);
  return (
    <Provider store={store}>
      <ToastContainer />
      <ChakraBaseProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraBaseProvider>
    </Provider>
  );
}

export default App;
