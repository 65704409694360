import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CFormErrors from "./CFormErrors";

function CUpload({ type, id, label, name, formik, changeAction, text }) {
  const {
    load: { loading },
  } = useSelector((state) => state?.files);
  const [fileUrl, setFileUrl] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  const maxUploadSize = useSelector(
    (state) => state?.systemConfig?.systemConfigs
  ).filter((ele) => ele?.key == "max_upload_size")[0];

  const oliveGreen = "green",
    orange = "orange",
    textGray = "gray";
  const errorsMsg = formik?.errors[name];

  const handleClose = () => {
    setFileUrl(null);
    changeAction("");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      uploadFile(files[0], type);
    }
  };
  const uploadFile = async (file, type) => {
    const imageExe = ["png", "jpg", "jpeg"];
    const extension = file?.type.slice(file?.type.indexOf("/") + 1);
    changeAction(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file, type);
    }
  };
  useEffect(() => {
    setError(formik?.errors[name]);
  }, [formik?.errors]);
  return (
    <Grid container>
      <Stack sx={{ mb: "15px" }}>
        <Text sx={{ fontFamily: "Jost", fontSize: "32px", fontWeight: "400" }}>
          {label}
        </Text>
      </Stack>
      {fileUrl || formik?.values?.[name] ? (
        <Grid
          Grid
          item
          xs={12}
          sx={{
            border: " 1px dashed rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            position: "relative",
            minHeight: "200px",
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            // height='100%'
            minHeight="214px" // Set a fixed height for the stack
          >
            <Box
              component="img"
              src={fileUrl || formik?.values?.[name]}
              alt="img"
              width={type == "icon" ? "95px" : "100%"}
              height={type == "icon" ? "95px" : "100%"}
              maxHeight="214px"
              sx={{
                borderRadius: "10px",
              }}
            />
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "48px",
              height: "48px",
              bgcolor: "white",
              borderRadius: "50%",
              filter: "drop-shadow(0px 0px 4px rgba(185, 185, 185, 0.25))",
              transform: "translate(50%, -30%)",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <IoIosCloseCircleOutline />
          </Stack>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              border: " 1px dashed rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <label htmlFor={`upload-${id}`}>
              <input
                id={`upload-${id}`}
                name={`upload-${id}`}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <Stack alignItems="center" rowGap={"24px"} py="3.5rem">
                {loading && openLoader ? (
                  <CircularProgress sx={{ color: oliveGreen }} />
                ) : (
                  <>
                    <img src={"icons?.uploadIcon"} alt="upload icon" />
                    <Text fontSize="13px">
                      Select a file or drag and drop here
                    </Text>
                    {text && (
                      <Text fontSize="12px" color={textGray}>
                        {text} {maxUploadSize?.value} KB
                      </Text>
                    )}
                    <Button
                      component="span"
                      sx={{
                        color: orange,
                        border: `1px solid ${orange}`,
                        fontSize: "15px",
                        px: "16px",
                      }}
                    >
                      Select File
                    </Button>
                  </>
                )}
              </Stack>
            </label>
          </Grid>
          <CFormErrors errorsMsg={error || errorsMsg} />
        </>
      )}
    </Grid>
  );
}

export default CUpload;
