import Layout from "../layout";
import { createBrowserRouter } from "react-router-dom";
import Login from "../../pages/login";
import Cities from "../../pages/cities";
import Areas from "../../pages/areas";
import Specialties from "../../pages/specialty";
import Subspecialties from "../../pages/subspecialty";
import Doctors from "../../pages/doctors";
import UnapprovedDoctors from "../../pages/doctors/unapproved";
import SuspendedDoctors from "../../pages/doctors/suspended";
import Patients from "../../pages/patients";
import SuspendedPatients from "../../pages/patients/suspended";
import Home from "../../pages/home/home";
import Privacypolics from "../../pages/privacypolicy/privacypolics";
import Entities from "../../pages/entites";
import UnapprovedVetDoctors from "../../pages/doctors/unapprovedVet";

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <Privacypolics />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/privacy-policy",
        element: <Privacypolics />,
      },
      {
        path: "/entities",
        element: <Entities />,
      },

      {
        path: "/cities",
        element: <Cities />,
      },

      {
        path: "/areas",
        element: <Areas />,
      },
      {
        path: "/specialties",
        element: <Specialties />,
      },
      {
        path: "/subspecialty",
        element: <Subspecialties />,
      },
      {
        path: "/doctors",
        element: <Doctors />,
      },
      {
        path: "/unapproved-doctors",
        element: <UnapprovedDoctors />,
      },
      {
        path: "/unapproved-vet-doctors",
        element: <UnapprovedVetDoctors />,
      },

      {
        path: "/suspended-doctors",
        element: <SuspendedDoctors />,
      },
      {
        path: "/patients",
        element: <Patients />,
      },
      {
        path: "/suspended-patients",
        element: <SuspendedPatients />,
      },
    ],
  },
]);
