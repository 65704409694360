import { loginSuccess, loginLoading, loginFailed } from "./Actions";
import { reduxRequest } from "../../../common/utils/reduxRequest";

export const loginRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "auth",
    isLoading: loginLoading,
    successFunction: loginSuccess,
    failFunction: loginFailed,
    url: `/login`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const contactusRequest = ({ ...variables }) => {
  const requestData = {
    loadingType: "contactus",
    isLoading: loginLoading,
    successFunction: loginSuccess,
    failFunction: loginFailed,
    url: `/contact_us`,
    action: variables?.action,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
