import {
  FormControl,
  FormLabel,
  MenuItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import CFormErrors from "./CFormErrors";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Select from "react-select";

const SelectOptions = ({
  options,
  changeAction,
  label,
  width = "100%",
  name,
  formik,

  optionsName,
}) => {
  const value = formik?.values[name];
  const errorsMsg = formik.errors[name];
  const customeOptions = options?.map((data) => {
    return {
      label: data?.[optionsName],
      value: data?.id,
    };
  });
  return (
    <Stack w={width} p="0" justifyContent="center">
      <Text
        as="span"
        sx={{
          color: "#797979",
          fontSize: "18px",
          fontFamily: "Jost",
          mb: 2,
        }}
      >
        {label}
      </Text>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value ? value : ""}
        onChange={(e) => {
          changeAction(e);
        }}
        options={customeOptions}
      />

      {errorsMsg && <CFormErrors errorsMsg={errorsMsg} />}
    </Stack>
  );
};

export default SelectOptions;
