import { Button, HStack, Input, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";

const AddMoreNames = ({ data, setData }) => {
  const [inputsLength, setInputsLength] = useState(0);
  const [value, setValue] = useState(null);
  return (
    <VStack w="100%">
      <HStack w="100%">
        <Input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            if (value) {
              setInputsLength((s) => s + 1);
              let currentData = data;
              currentData[`${inputsLength}`] = value;
              setData((s) => (s = currentData));
              setValue(null);
            }
          }}
        >
          <FaPlus color="black" /> Add More
        </Button>
      </HStack>
      {[...Array(inputsLength)].map((_, index) => {
        return (
          <HStack width="100%" justifyContent="space-around">
            <Text> {data?.[index]}</Text>
            <Button
              onClick={() => {
                let currentData = data;
                currentData?.splice(index, 1);
                setData((s) => (s = currentData));
                setInputsLength((s) => s - 1);
              }}
            >
              <MdOutlineDelete fontSize="30px" color="red" />
            </Button>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default AddMoreNames;
