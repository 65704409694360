import React from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { IoIosMore } from "react-icons/io";
import { IoChevronDownCircleOutline } from "react-icons/io5";

const CActions = ({
  id,
  index,
  data,
  handleClick,
  handleClose,
  open,
  actionsList,
}) => {
  return (
    <Menu placement="left">
      <MenuButton as={Button}>
        <IoIosMore
          className="moreMenu"
          style={{
            color: "#333132",
            transform: "rotate(90deg)",
          }}
        />
      </MenuButton>
      <MenuList>
        {actionsList({ id, index, data })?.map((action, index) => {
          return (
            action.condition(index) === true && (
              <MenuItem
                key={index}
                onClick={() => {
                  action.click();
                  handleClose();
                }}
              >
                {action.text}
              </MenuItem>
            )
          );
        })}
      </MenuList>
    </Menu>
    // <Box
    //   style={{
    //     position: "relative",
    //     width: "fit-content",
    //   }}
    // >
    //   <Button
    //     id="demo-positioned-button"
    //     aria-controls={open ? "demo-positioned-menu" : undefined}
    //     aria-haspopup="true"
    //     aria-expanded={open ? "true" : undefined}
    //     onClick={(e) => handleClick(e, index, data)}
    //     sx={{
    //       width: "15px !important",
    //       display: "flex",
    //       p: 0,
    //       justifyContent: "start",
    //     }}
    //   >
    //     <IoIosMore
    //       className="moreMenu"
    //       style={{
    //         color: "#333132",
    //         transform: "rotate(90deg)",
    //       }}
    //     />
    //   </Button>
    //   <Menu>

    //   </Menu>
    // </Box>
  );
};

export default CActions;
