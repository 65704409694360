import Types from "./Types";

export const GetSubspecialtiesSuccess = (payload) => ({
  type: Types.GET_SUBSPECIALTIES_SUCCESS,
  payload,
});

export const SubspecialtiesLoading = (payload) => ({
  type: Types.SUBSPECIALTIES_LOADING,
  payload,
});

export const GetSubspecialtyDetailsSuccess = (payload) => ({
  type: Types.GET_SUBSPECIALTY_DETAILS_SUCCESS,
  payload,
});

export const DeleteSubspecialtySuccess = (payload, data) => ({
  type: Types.DELETE_SUBSPECIALTY_SUCCESS,
  payload: { payload, data },
});

export const UpdateSubspecialtySuccess = (payload, data) => ({
  type: Types.UPDATE_SUBSPECIALTY_SUCCESS,
  payload: payload,
  data: data,
});
export const CreateSubspecialtySuccess = (payload, data) => ({
  type: Types.CREATE_SUBSPECIALTY_SUCCESS,
  payload: payload,
  data: data,
});
