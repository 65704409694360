import {
  Stack,
  Text,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Accordion,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import Logo from "../../../assets/logo.png";

const SideNavbar = ({ setExpanded, expanded }) => {
  const oliveGreen = "green";
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const sidebarNavs = [
    {
      title: "Specialties",
      icon: <></>,
      path: "",
      type: "",
      list: [
        { title: "Specialties", path: "/specialties", type: "normal" },
        { title: "Sub-Specialties", path: "/subspecialty", type: "normal" },
      ],
    },

    {
      title: "Cities",
      icon: <></>,
      path: "",
      type: "",
      list: [
        { title: "Cities", path: "/cities", type: "normal" },
        { title: "Areas", path: "/areas", type: "normal" },
      ],
    },

    {
      title: "Entities",
      icon: <></>,
      path: "/entities",
      type: "normal",
    },

    {
      title: "Doctors",
      icon: <></>,
      path: "",
      type: "",
      list: [
        {
          title: "Doctors",
          path: "/doctors",
          type: "normal",
        },
        {
          title: "UnApproved Doctors ",
          path: "/unapproved-doctors",
          type: "normal",
        },
        {
          title: "UnApproved Vet Doctors ",
          path: "/unapproved-vet-doctors",
          type: "normal",
        },

        {
          title: "Suspended Doctors ",
          path: "/suspended-doctors",
          type: "normal",
        },
      ],
    },

    {
      title: "Patients",
      icon: <></>,
      path: "",
      type: "",
      list: [
        {
          title: "Patients",
          path: "/patients",
          type: "normal",
        },

        {
          title: "Suspended Patients ",
          path: "/suspended-patients",
          type: "normal",
        },
      ],
    },
  ];

  return (
    <Stack
      bgcolor={"#F5F5F5"}
      className={"custom-scroll"}
      width={{ base: expanded ? "50%" : "0", md: "100%" }}
      position={{ base: "fixed", md: "relative" }}
      zIndex={100}
      h="100%"
    >
      <Stack
        width="100%"
        direction="column"
        p={2}
        sx={{
          backgroundColor: "#1A1A1A",
          height: "100%",
          overflowY: expanded && "scroll",
        }}
      >
        <Image
          src={Logo}
          width="130px"
          onClick={() => navigate("/")}
          cursor="pointer"
          alignSelf="center"
        />
        <Box
          display={{ base: expanded ? "flex" : "none", md: "flex" }}
          flexDirection="column"
        >
          {sidebarNavs?.map((data, index) => {
            return data?.type === "normal" ? (
              <Stack
                direction="row"
                key={index}
                display={expanded ? "flex" : "none"}
                sx={{
                  bgcolor: path === data.path ? oliveGreen : "",
                  height: "50px",
                }}
                onClick={() => setExpanded(false)}
              >
                <NavLink
                  to={data?.path}
                  style={{
                    fontSize: "16px",
                    textDecoration: "none",
                    color: "white",
                    marginInlineStart: "5px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data?.title}
                </NavLink>
              </Stack>
            ) : (
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem border="0" width="100%">
                  <h2>
                    <AccordionButton
                      p="5px"
                      color="white"
                      width="100%"
                      border="0"
                      justifyContent="space-between"
                    >
                      <Text color="white">{data?.title}</Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {data?.list?.map((val, idx) => {
                      return (
                        <NavLink
                          to={val?.path}
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textDecoration: "none",
                            color: "white",
                            width: "100%",
                            backgroundColor:
                              path === val?.path ? "white" : "transparent",
                          }}
                        >
                          <Text
                            component="span"
                            sx={{
                              marginBlock: "10px",

                              color: path === val?.path ? "gray.500" : "white",
                              opacity: ".8",
                              mr: "5px",
                              fontSize: "16px",
                              textAlign: "start",
                            }}
                          >
                            {val?.title}
                          </Text>
                        </NavLink>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );
          })}
        </Box>
      </Stack>
      <Button
        zIndex={10}
        onClick={handleChange}
        right={expanded ? "-10px" : ""}
        left={!expanded ? "10px" : ""}
        top={1}
        bgColor="#89a8ebe3"
        borderRadius="0 8px 8px 0"
        width="40px"
        position="absolute"
      >
        {expanded && <FaArrowRight color="white" fontSize="20px" />}
        {!expanded && <FaArrowLeft color="white" fontSize="20px" />}{" "}
      </Button>
    </Stack>
  );
};

export default SideNavbar;
