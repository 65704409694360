import { Button, Stack, Text } from "@chakra-ui/react";
import React from "react";

const FormHeader = ({ handleModelClose, form, formikData, refsubmit }) => {
  const red = "red",
    oliveGreen = "green";
  return (
    <Stack
      flexDirection="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid rgb(217, 217, 217) "
    >
      <Text fontSize="30px" fontWeight="600" fontFamily="Jost">
        {form?.title}
      </Text>
      <Stack
        my={2}
        flexDirection="row"
        gap={2}
        className={form?.className?.actionButton}
      >
        {/* add custom header, add cancel, save to it */}
        <Button
          onClick={(e) => {
            handleModelClose();
          }}
          variant="outline"
        >
          Cancel
        </Button>
        <Button onClick={() => refsubmit?.current?.click()} variant="solid">
          {form?.submitTitle ? form?.submitTitle : "Save Changes"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default FormHeader;
