import React, { useMemo } from "react";
import { LuArrowDownUp } from "react-icons/lu";
import { IoMdArrowUp } from "react-icons/io";
import { IoArrowDownOutline } from "react-icons/io5";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Footer from "./footer";
import Header from "./header";
import DataFiltration from "./components/dataFiltration";

import {
  Stack,
  TableContainer,
  Text,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const CTable = ({
  Data,
  Columns,
  Title,
  emptyListMsg,
  searchAttribute,
  isChecked,
  isSearch,
  setFilterObj,
  selectedData,
  currentpage,
  totalPage,
  checked,
  setChecked,
  headerChildren,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  filterList,
  className,
  total_count,
  paginationPage,
  searchInputPlaceholder,
  handlePageChange,
  isDetails = true,
  path,
}) => {
  const navigate = useNavigate();
  const data = useMemo(
    () =>
      Data &&
      Data?.map((value, i) => {
        return {
          ...value,
        };
      }),
    [Data]
  );

  const columns = useMemo(
    () =>
      Columns?.map((column) => {
        return {
          Header: column.Header,
          accessor: column.accessor,
          sort: column?.sort,
          width: column?.width,
        };
      }),
    [Columns]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        useControlledState: (state) => {
          return React.useMemo(
            () => ({
              ...state,
              pageIndex: currentpage,
            }),
            [state, currentpage]
          );
        },
        initialState: { pageIndex: currentpage },
        manualPagination: true,
        pageCount: totalPage,
        autoResetHiddenColumns: false,
        autoResetSortBy: false,
        autoResetPage: false,
      },

      useGlobalFilter,
      useSortBy,
      usePagination
    );

  return (
    <Stack gap={3} w="100%">
      <Header
        Title={Title}
        isSearch={isSearch}
        setFilterObj={setFilterObj}
        searchAttribute={searchAttribute}
        createBtnTitle={createBtnTitle}
        createBtnFun={createBtnFun}
        CreateBtnIcon={CreateBtnIcon}
        handlePageChange={handlePageChange}
        searchInputPlaceholder={searchInputPlaceholder}
        children={headerChildren}
      />
      <DataFiltration
        filterList={filterList}
        handlePageChange={handlePageChange}
      />
      {/* {console.log(page)} */}
      {total_count == 0 ? (
        <Stack justifyContent={"center"} direction="row">
          <Text sx={{ fontSize: "32px", color: "gray" }}>No Results</Text>
        </Stack>
      ) : (
        <>
          <TableContainer
            height="100%"
            className={className}
            sx={{
              boxShadow: "none",
              borderRadius: "8px 8px 0px 0px",
              w: "100%",
              pb: 5,
            }}
          >
            <Table
              {...getTableProps()}
              bg="white"
              mb="6"
              size="small"
              width="auto"
              w="100%"
            >
              <Thead bg="#efeeee" color="#645f65" boxShadow="md">
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Td
                        padding={2}
                        key={index}
                        bg="inherit"
                        {...column.getHeaderProps(
                          column.Header === "" || !column.sort
                            ? ""
                            : column.getSortByToggleProps()
                        )}
                        sx={{
                          py: 2,
                        }}
                      >
                        <Stack direction="row">
                          {Data?.length > 0 ? (
                            <>
                              <Text
                                fontWeight="500"
                                fontSize="16px"
                                fontFamily="Jost"
                                color={index === 0 ? "#1A1A1A" : "#9F9F9F"}
                              >
                                {column.render("Header")}
                              </Text>
                              {column.Header === "" ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    paddingLeft: "10px",
                                    margin: "-1px",
                                  }}
                                  display="inline-block"
                                >
                                  {column.sort &&
                                    (column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <IoArrowDownOutline
                                          sx={{ color: "#9F9F9F" }}
                                        />
                                      ) : (
                                        <IoMdArrowUp
                                          sx={{ color: "#9F9F9F" }}
                                        />
                                      )
                                    ) : (
                                      <LuArrowDownUp
                                        sx={{ color: "#9F9F9F" }}
                                      />
                                    ))}
                                </span>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Stack>
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Thead>
              {Data.length > 0 ? (
                <Tbody {...getTableBodyProps()} color="black" p={1}>
                  {page?.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr
                        key={index}
                        {...row.getRowProps()}
                        onClick={() => {
                          selectedData(row.original);
                          // setSelectedRow && setSelectedRow(row.original);
                        }}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <Td
                              padding={2}
                              key={index}
                              sx={{
                                color: "#1A1A1A",
                                fontWeight: "400",
                                fontSize: "16px",
                                fontFamily: "Jost",
                                position: "relative",
                                textTransform: "capitalize",
                                cursor: index == 0 && isDetails && "pointer",
                              }}
                              onClick={() => {
                                index == 0 &&
                                  isDetails &&
                                  navigate(
                                    path
                                      ? `/${path}?type=details&id=${row?.original?.id}`
                                      : `?type=details&id=${row?.original?.id}`
                                  );
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              ) : (
                <Tbody>
                  <Td
                    sx={{
                      width: "100% !important",
                      borderBottom: "none !important",
                      padding: "0px !important",
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: "20px",
                        color: "#333132",
                        textAlign: "center",
                      }}
                    >
                      {emptyListMsg}
                    </Text>
                  </Td>
                </Tbody>
              )}
            </Table>
          </TableContainer>
          <Footer
            totalPage={totalPage}
            total_count={total_count}
            paginationPage={paginationPage}
            handlePageChange={handlePageChange}
          />
        </>
      )}
    </Stack>
  );
};

export default CTable;
