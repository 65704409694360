import React, { useState } from "react";
import CCrud from "../../../components/molecules/crud";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { getSuspendedDoctorsRequest } from "../../../services/modules/doctors";

const SuspendedDoctors = ({ paginationPage }) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.doctors.suspendedDoctors);
  const count = useSelector((state) => state.doctors.count);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async () => {
    dispatch(getSuspendedDoctorsRequest());
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    getList();
  }, []);

  return (
    <Box w="100%">
      <CCrud
        table={{
          list: mappedData,
          listTitle: "Suspended Doctors",
          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "full_name",
              Header: "Doctor name",
              sort: true,
            },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by Sub specialties name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          className: "organization-table system-configurations-table",
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          filterList,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
    </Box>
  );
};

export default SuspendedDoctors;
