import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
  };
};
export const structure = () => [
  {
    name: "name",
    kind: "input",
    type: "text",
    label: "Specialty name",
    width: "80%",
    placeholder: "Write the specialty name",
  },
];

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required("Required"),
  });
