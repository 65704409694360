const Types = {
  //! cities
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITY_DETAILS_SUCCESS: "GET_CITY_DETAILS_SUCCESS",
  CITIES_LOADING: "CITIES_LOADING",
  DELETE_CITY_SUCCESS: "DELETE_CITY_SUCCESS",
  UPDATE_CITY_SUCCESS: "UPDATE_CITY_SUCCESS",
  CREATE_CITY_SUCCESS: "CREATE_CITY_SUCCESS",
};

export default Types;
