const Types = {
  //! SUBSPECIALTYS
  GET_DOCTORS_SUCCESS: "GET_DOCTORS_SUCCESS",
  DOCTORS_LOADING: "DOCTORS_LOADING",
  GET_SUSPENDED_DOCTORS_SUCCESS: "GET_SUSPENDED_DOCTORS_SUCCESS",
  GET_UNAPPROVED_DOCTORS_SUCCESS: "GET_UNAPPROVED_DOCTORS_SUCCESS",
  GET_DOCTOR_DETAILS_SUCCESS: "GET_DOCTOR_DETAILS_SUCCESS",
  SUSPEND_DOCTOR_SUCCESS: "SUSPEND_DOCTOR_SUCCESS",
  GET_UNAPPROVED_VET_DOCTORS_SUCCESS: "GET_UNAPPROVED_VET_DOCTORS_SUCCESS",
  APPROVE_DOCTOR_SUCCESS: "APPROVE_DOCTOR_SUCCESS",
};

export default Types;
