/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  login: {
    admin: {},
    isLoading: {
      type: "",
      loading: false,
    },
    error: null,
  },
};

export default function authReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.LOGIN_FAILED: {
      return {
        ...state,
        login: {
          ...state.login,
          error: { message: payload?.response?.data?.message },
        },
      };
    }
    case Types.LOGIN_LOADING: {
      return {
        ...state,
        login: { ...state.login, isLoading: payload },
      };
    }
    case Types.LOGIN_SUCCESS: {
      return {
        ...state,
        login: {
          ...state.login,
          admin: {
            ...payload?.data?.admin,
            token: payload?.extra?.access_token,
          },
          error: null,
        },
      };
    }

    default: {
      return state;
    }
  }
}
