/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  specialties: [],
  specialty: {},
  count: 0,
  load: null,
};

export function specialtiesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.SPECIALTIES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_SPECIALTIES_SUCCESS: {
      return {
        ...state,
        specialties: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.DELETE_SPECIALTY_SUCCESS: {
      const id = payload?.data;
      const newData = state.specialties?.filter((data) => data?.id != id);
      return {
        ...state,
        specialties: newData,
      };
    }
    case Types.CREATE_SPECIALTY_SUCCESS: {
      return {
        ...state,
        specialties: [...state?.specialties, payload?.data],
      };
    }

    case Types.GET_SPECIALTY_DETAILS_SUCCESS: {
      return {
        ...state,
        specialty: payload?.data,
      };
    }
    case Types.UPDATE_SPECIALTY_SUCCESS: {
      console.log(payload);
      const newData = payload?.data;
      let newState = state.specialties;
      let dataIndx;
      state.specialties.map((data, index) => {
        if (data?.id == newData?.id) dataIndx = index;
      });
      newState[dataIndx] = newData;
      return {
        ...state,
        specialties: newState,
      };
    }

    default: {
      return state;
    }
  }
}
