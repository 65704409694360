const modalTypes = {
  IS_OPEN_DOCTOR_QR_CODE: "IS_OPEN_DOCTOR_QR_CODE",
  IS_OPEN_PATIENT_QR_CODE: "IS_OPEN_PATIENT_QR_CODE",
  IS_OPEN_ASSIGN_TO_FREELANCER: "IS_OPEN_ASSIGN_TO_FREELANCER",
  IS_OPEN_DRIVER_DETAILS: "IS_OPEN_DRIVER_DETAILS",
  IS_OPEN_VIEW_COMPLAIN: "IS_OPEN_VIEW_COMPLAIN",
  IS_OPEN_FOLLOWES_MODEL: "IS_OPEN_FOLLOWES_MODEL",
  IS_OPEN_FOLLOWING_MODEL: "IS_OPEN_FOLLOWING_MODEL",
  IS_OPEN_DOCTOR_DETAILS_MODEL: "IS_OPEN_DOCTOR_DETAILS_MODEL",
  CLOSE_SUCCESS_MODAL: "CLOSE_SUCCESS_MODAL",
};
export default modalTypes;
