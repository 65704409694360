import React, { useState } from "react";
import Header from "../../common/layout/Header";
import {
  VStack,
  HStack,
  Image,
  Text,
  Container,
  Flex,
  Button,
  Heading,
  Input,
  Box,
  Textarea,
  Stack,
} from "@chakra-ui/react";

import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { contactusRequest } from "../../services/modules/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import Logo from "../../assets/logo.png";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contactUsData, SetContactusData] = useState({
    message: "",
    email: "",
  });

  const handelSendContactusData = () => {
    const requestData = {
      action: (data) => {
        toast.success("your message sent successfully");
      },
      body: contactUsData,
    };
    if (contactUsData?.email && contactUsData?.phone_number)
      dispatch(contactusRequest(requestData));
    else toast.error("please write valid phone number and email");
  };
  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        sx={{ boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}
        p={2}
      >
        <Image
          src={Logo}
          width="100px"
          onClick={() => navigate("/")}
          cursor="pointer"
        />
        <Header />
      </Stack>

      <Container maxW="container.xl" my={2} mb={20}>
        <VStack alignItems="end" gap={20} mt={10}>
          <VStack
            alignItems="end"
            gap={10}
            w="100%"
            boxShadow="0px 0px 5px #8080808c"
            borderRadius="8px"
            p={8}
          >
            <Heading
              as={"h6"}
              textTransform={"uppercase"}
              color="#FF9138"
              fontSize="28px"
              fontWeight="700"
              alignSelf="center"
            >
              تواصل معنا
            </Heading>

            <Flex
              alignItems="start"
              gap={5}
              w="100%"
              flexDirection={{ base: "column", md: "row" }}
            >
              <VStack w={{ base: "100%", md: "45%" }} gap={10}>
                <Flex
                  gap={8}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  w="100%"
                >
                  <Box w="100%" dir="rtl">
                    <Text fontSize={"26px"} color="#3D3D95" mb={2}>
                      البريد الالكتروني
                    </Text>
                    <Input
                      type="email"
                      style={{
                        width: "100%",
                        padding: "20px",
                        objectFit: "contain",
                        borderRadius: "10px",
                        boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                        border: "solid 1px #010038",
                        backgroundColor: "#fff",
                      }}
                      onChange={(e) =>
                        SetContactusData({
                          ...contactUsData,
                          email: e?.target?.value,
                        })
                      }
                    />
                  </Box>
                  <Box w="100%" dir="rtl">
                    <Text fontSize={"26px"} color="#3D3D95" mb={2}>
                      رقم الجوال
                    </Text>
                    <Input
                      type="number"
                      style={{
                        width: "100%",
                        padding: "20px",
                        objectFit: "contain",
                        borderRadius: "10px",
                        boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                        border: "solid 1px #010038",
                        backgroundColor: "#fff",
                      }}
                      onChange={(e) =>
                        SetContactusData({
                          ...contactUsData,
                          phone_number: e?.target?.value,
                        })
                      }
                    />
                  </Box>
                  <Box w="100%" dir="rtl">
                    <Text fontSize={"26px"} color="#3D3D95" mb={2}>
                      الرسالة
                    </Text>
                    <Textarea
                      style={{
                        width: "100%",
                        padding: "20px",
                        objectFit: "contain",
                        borderRadius: "10px",
                        boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                        border: "solid 1px #010038",
                        backgroundColor: "#fff",
                      }}
                      onChange={(e) =>
                        SetContactusData({
                          ...contactUsData,
                          message: e?.target?.value,
                        })
                      }
                    />
                  </Box>
                </Flex>
                <Button
                  onClick={handelSendContactusData}
                  type="submit"
                  w="30%"
                  p="22px"
                  alignSelf={"end"}
                  mx="10px"
                  bg="#3D3D95"
                  color="white"
                >
                  اترك رسالة
                </Button>
              </VStack>
              <VStack
                w={{ base: "100%", md: "50%" }}
                align="end"
                mt={10}
                gap={5}
              >
                <HStack>
                  <Text>
                    Alexandria ,٩١ محطة ترام سيدي جابر الشيخ امام مدارس سيدي
                    جابر
                  </Text>
                  <Flex
                    border="2px solid #3D3D95"
                    borderRadius="50%"
                    w="45px"
                    h="45px"
                    align="center"
                    justify="center"
                  >
                    <IoLocationOutline fontSize="30px" color="#3D3D95" />
                  </Flex>
                </HStack>
                <HStack>
                  <Text>000</Text>
                  <Flex
                    border="2px solid #3D3D95"
                    borderRadius="50%"
                    w="45px"
                    h="45px"
                    align="center"
                    justify="center"
                  >
                    <MdOutlineLocalPhone fontSize="30px" color="#3D3D95" />
                  </Flex>
                </HStack>
                <HStack>
                  <Text>Dr_haitham_adel@yahoo</Text>
                  <Flex
                    border="2px solid #3D3D95"
                    borderRadius="50%"
                    w="45px"
                    h="45px"
                    align="center"
                    justify="center"
                  >
                    <MdOutlineMailOutline fontSize="30px" color="#3D3D95" />
                  </Flex>
                </HStack>
              </VStack>
            </Flex>
          </VStack>
          <VStack alignItems="center" gap={5} w="100%">
            <Text color="#FF9138" fontSize="28px" fontWeight="700">
              أول مجتمع طبي متكامل
            </Text>
            <Text color="#3D3D95" fontSize="18px" fontWeight="500">
              حمل التطبيق الآن واستمتع بسهولة البحث والحجز مع أشهر الأطباء وأكثر
              العيادات تميزاً
            </Text>

            <Stack flexDirection={{ base: "column", md: "row" }}>
              <HStack
                bg="black"
                color="white"
                borderRadius="10px"
                p={4}
                cursor="pointer"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.app.consolto",
                    "_blank"
                  )
                }
              >
                <FaGooglePlay fontSize="30px" />
                <Text color="white">
                  Availbale on
                  <br /> <strong>the google play</strong>
                </Text>
              </HStack>
              <HStack
                bg="black"
                color="white"
                borderRadius="10px"
                p={4}
                cursor="pointer"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/consolto/id6475204243",
                    "_blank"
                  )
                }
              >
                <FaApple fontSize="30px" />
                <Text color="white">
                  Availbale on
                  <br /> <strong>the App store</strong>
                </Text>
              </HStack>
            </Stack>
          </VStack>
        </VStack>
      </Container>

      <Stack
        mt={6}
        justifyContent="space-between"
        w="100%"
        width="100%"
        bgcolor="white"
        direction="row"
        alignItems="center"
        fontWeight="500"
        sx={{ boxShadow: "0px -2px 1px rgba(0, 0, 0, 0.1)" }}
        px={20}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Image
          src={Logo}
          width="100px"
          onClick={() => navigate("/")}
          cursor="pointer"
        />

        <VStack alignItems="start">
          <Link to="/privacy-policy">Privacy policy</Link>
          <Link to="/login">Login</Link>
        </VStack>
      </Stack>
    </>
  );
};

export default Home;
