import Types from "./Types";

export const GetCitiesSuccess = (payload) => ({
  type: Types.GET_CITIES_SUCCESS,
  payload,
});

export const CitiesLoading = (payload) => ({
  type: Types.CITIES_LOADING,
  payload,
});

export const GetCityDetailsSuccess = (payload) => ({
  type: Types.GET_CITY_DETAILS_SUCCESS,
  payload,
});

export const DeleteCitySuccess = (payload, data) => ({
  type: Types.DELETE_CITY_SUCCESS,
  payload: { payload, data },
});

export const UpdateCitySuccess = (payload, data) => ({
  type: Types.UPDATE_CITY_SUCCESS,
  payload: payload,
  data: data,
});
export const CreateCitySuccess = (payload, data) => ({
  type: Types.CREATE_CITY_SUCCESS,
  payload: payload,
  data: data,
});
