import { Button, Input, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const Header = ({
  Title,
  isSearch,
  setFilterObj,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  handlePageChange,
  searchInputPlaceholder,
  children,
}) => {
  const [value] = useState(null);
  useEffect(() => {
    const timeId =
      value !== null &&
      setTimeout(() => {
        setFilterObj((data) => {
          return {
            ...data,
            keyword: value,
          };
        });
        handlePageChange(1);
      }, 500);
    return () => clearTimeout(timeId);
  }, [value]);

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      alignItems={{ base: "start", md: "center" }}
      justifyContent={{ base: "start", md: "space-between" }}
      sx={{ mt: 2 }}
      w="100%"
    >
      <Stack width={{ base: "100%", md: "50%" }}>
        <Text fontSize="32px" fontWeight="500" fontFamily="Jost">
          {Title}
        </Text>
      </Stack>

      <Stack
        direction="row"
        width={{ base: "100%", md: "50%" }}
        gap={2}
        alignSelf={{ base: "start", md: "end" }}
        justifyContent={{ base: "start", md: "end" }}
      >
        <>{isSearch && <Input />}</>
        {children}
        {createBtnTitle && (
          <Button onClick={createBtnFun} variant="solid">
            {CreateBtnIcon && (
              <CreateBtnIcon sx={{ position: "absolute", left: "20px" }} />
            )}

            {createBtnTitle}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;

// <Stack flexDirection="row" bgcolor="white" borderRadius="10px">
// <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
//   <SearchIcon />
// </IconButton>
// <InputBase
//   sx={{ ml: 1, width: "100%" }}
//   placeholder={searchInputPlaceholder}
//   type={"text"}
//   size="small"
// onChange={(e) => {
//   setValue(e.target.value);
// }}
// />
// </Stack>
