/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  cities: [],
  city: {},
  count: 0,
  load: null,
};

export function citiesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.CITIES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.DELETE_CITY_SUCCESS: {
      const id = payload?.data;
      const newData = state.cities?.filter((data) => data?.id != id);
      return {
        ...state,
        cities: newData,
      };
    }
    case Types.CREATE_CITY_SUCCESS: {
      return {
        ...state,
        cities: [...state?.cities, payload?.data],
      };
    }

    case Types.GET_CITY_DETAILS_SUCCESS: {
      return {
        ...state,
        city: payload?.data?.city,
      };
    }
    case Types.UPDATE_CITY_SUCCESS: {
      console.log(payload);
      const newData = payload?.data;
      let newState = state.cities;
      let dataIndx;
      state.cities.map((data, index) => {
        if (data?.id == newData?.id) dataIndx = index;
      });
      newState[dataIndx] = newData;
      return {
        ...state,
        cities: newState,
      };
    }

    default: {
      return state;
    }
  }
}
