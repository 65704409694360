import React, { useState } from "react";
import CCrud from "../../components/molecules/crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { Box } from "@chakra-ui/react";

import {
  approveEntityRequest,
  getEntitiesRequest,
  suspendEntityRequest,
  unapprovedEntitiesRequest,
} from "../../services/modules/entities";
import { toast } from "react-toastify";

const Entities = ({ paginationPage }) => {
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const list = useSelector((state) => state.entities.unapprovedEntities);
  const count = useSelector((state) => state.entities.count);
  const entities = useSelector((state) => state.entities.entities);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [, setPage] = useState(1);
  const [, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (id) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
      },
      id,
    };
    dispatch(unapprovedEntitiesRequest(requestData));
  };
  const getEntities = async () => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
      },
    };
    dispatch(getEntitiesRequest(requestData));
  };

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const handelSubmit = (data, formik) => {
    // const { name, nameAr, isFulfillmentCenter, fulfillmentCenter, fees } = data;
  };
  const entitiesOptions = entities?.map((data) => {
    console.log(data);
    return {
      entity: data?.name,
      id: data?.name,
    };
  });
  const filterList = [
    {
      name: "entity",
      label: "Entities",
      type: "select",
      onChange: (value) => {
        console.log(value);
        getList(value?.value);
      },
      options: entitiesOptions,
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(
            approveEntityRequest({
              id: data?.id,
              action: () => {
                toast.success("approved successufly");
              },
            })
          );
        },
        text: "Approve",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(
            suspendEntityRequest({
              id: data?.id,
              action: () => {
                toast.success("suspended successufly");
              },
            })
          );
        },
        text: "Suspend",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    getEntities();
  }, []);
  console.log(list);
  return (
    <Box w="100%">
      <CCrud
        table={{
          list: list,
          listTitle: "Entities",

          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "full_name",
              Header: "Entity name",
              sort: true,
            },

            { accessor: "Actions", Header: "Actions" },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by Entities name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          filterList,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        form={{
          initialValues: !id ? {} : initialValues(selectedRow?.rowData),
          structure: () => structure(id),
          validationSchema: validationSchema,
          title: id ? "Edit Entities" : "Add Entities",
          submitTitle: id ? "Update" : "Add",
          onSubmit: handelSubmit,
          className: { form: "main-form", actionButton: "main-form-buttons" },
          // formChildren,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
    </Box>
  );
};

export default Entities;
