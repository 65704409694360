import Types from "./Types";

export const loginSuccess = (payload) => ({
  type: Types.LOGIN_SUCCESS,
  payload,
});

export const loginLoading = (payload) => ({
  type: Types.LOGIN_LOADING,
  payload,
});
export const loginFailed = (payload) => ({
  type: Types.LOGIN_FAILED,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: Types.LOGOUT_SUCCESS,
});
