import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";

function FormHead({ text }) {
  const style = {
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#333132",
    borderBottom: "2px solid var(--primary-color)",
    width: "100%",
  };
  return (
    <Text component="h1" my={2} style={style}>
      {text}
    </Text>
  );
}

FormHead.propTypes = {
  text: PropTypes.string,
};

export default FormHead;
