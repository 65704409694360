import React, { useState } from "react";
import CCrud from "../../components/molecules/crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createCityRequest,
  deleteCityRequest,
  getCitiesRequest,
  getCityDetailsRequest,
  updateCityRequest,
} from "../../services/modules/cities/cities";
import { Box, Text, VStack } from "@chakra-ui/react";
import AddMoreNames from "../../components/addMoreNames";
import { toast } from "react-toastify";

const Cities = ({ deleteFun, paginationPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const [supportedLang, setSupportedLang] = useState("en");
  const list = useSelector((state) => state.cities.cities);
  const count = useSelector((state) => state.cities.count);
  const load = useSelector((state) => state.cities.load);
  console.log(list);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const [areasNames, setAreasNames] = useState([]);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getCitiesRequest(requestData));
  };

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const handelSubmit = (data, formik) => {
    // const { name, nameAr, isFulfillmentCenter, fulfillmentCenter, fees } = data;
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/cities");
      !id && formik?.resetForm();
      toast.success(
        ` ${id ? "City" : "New City"} has been ${
          id ? "updated" : "created"
        } successfully`
      );
    };
    const requestData = {
      body: {
        ...data,
        areas: areasNames,
      },
      id: id,
      action,
    };
    if (id) {
      //update call
      delete requestData?.body?.Actions;
      dispatch(updateCityRequest(requestData));
    } else {
      //create call
      dispatch(createCityRequest(requestData));
    }
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(
            deleteCityRequest({
              id: data?.id,
            })
          );
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    getList();
  }, []);

  const formChildren = (
    <VStack p={3} w="70%" gap={2} alignItems="start">
      <Text
        as="span"
        sx={{
          color: "#797979",
          fontSize: "18px",
          fontFamily: "Jost",
        }}
      >
        Areas Names
      </Text>
      <AddMoreNames setData={setAreasNames} data={areasNames} />
    </VStack>
  );
  return (
    <Box w="100%">
      <CCrud
        table={{
          list: list,
          listTitle: "Cities",
          createBtnTitle: "New City",
          CreateBtnIcon: IoIosAdd,
          createBtnFun: () => navigate("?type=form"),
          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "city_name",
              Header: "City name",
              sort: false,
            },
            {
              accessor: "name_ar",
              Header: "City Arabic name",
              sort: false,
            },
            { accessor: "Actions", Header: "Actions" },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by city name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          className: "organization-table system-configurations-table",
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        form={{
          initialValues: !id ? {} : initialValues(selectedRow?.rowData),
          structure: () => structure(),
          validationSchema: validationSchema,
          deleteFun: deleteFun,
          title: id ? "Edit City" : "Add City",
          submitTitle: id ? "Update" : "Add",
          onSubmit: handelSubmit,
          className: { form: "main-form", actionButton: "main-form-buttons" },
          formChildren,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
    </Box>
  );
};

export default Cities;
