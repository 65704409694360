import Types from "./Types";

export const GetDoctorsSuccess = (payload) => ({
  type: Types.GET_DOCTORS_SUCCESS,
  payload,
});

export const DoctorsLoading = (payload) => ({
  type: Types.DOCTORS_LOADING,
  payload,
});

export const GetSuspendedDoctorsSuccess = (payload, data) => ({
  type: Types.GET_SUSPENDED_DOCTORS_SUCCESS,
  payload: payload,
});

export const GetUnapprovedDoctorsSuccess = (payload, data) => ({
  type: Types.GET_UNAPPROVED_DOCTORS_SUCCESS,
  payload: payload,
});

export const GetUnapprovedVetDoctorsSuccess = (payload, data) => ({
  type: Types.GET_UNAPPROVED_VET_DOCTORS_SUCCESS,
  payload: payload,
});

export const GetDoctorDetailsSuccess = (payload) => ({
  type: Types.GET_DOCTOR_DETAILS_SUCCESS,
  payload,
});

export const SuspendDoctorSuccess = (payload, data) => ({
  type: Types.SUSPEND_DOCTOR_SUCCESS,
  payload: { payload, data },
});

export const ApproveDoctorSuccess = (payload, data) => ({
  type: Types.APPROVE_DOCTOR_SUCCESS,
  payload: { payload, data },
});
