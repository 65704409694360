import React, { useState } from "react";
import CCrud from "../../components/molecules/crud";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box } from "@chakra-ui/react";

import {
  getPatientsRequest,
  suspendPatientRequest,
} from "../../services/modules/patients";
import QrCode from "../../components/molecules/patients/models/qr-code";
import { isOpenPatientQrCode } from "../../services/modules/modal/Actions";

const Patients = ({ paginationPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  const list = useSelector((state) => state.patients.patients);
  const count = useSelector((state) => state.patients.count);
  const load = useSelector((state) => state.patients.load);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async () => {
    dispatch(getPatientsRequest());
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const filterList = [];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ id, index, data }) => {
    console.log(id, data);
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(
            suspendPatientRequest({
              id: data?.id,
            })
          );
        },
        text: "Suspend",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(isOpenPatientQrCode());
        },
        text: "Qr code",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box w="100%">
      <CCrud
        table={{
          list: list,
          listTitle: "Patients",
          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "full_name",
              Header: "Patient name",
              sort: false,
            },
            {
              accessor: "phone",
              Header: "Patient phone",
              sort: false,
            },
            {
              accessor: "date_of_birth",
              Header: "Patient birthdate",
              sort: false,
            },
            {
              accessor: "email",
              Header: "Patient email",
              sort: false,
            },
            {
              accessor: "gender",
              Header: "Patient gender",
              sort: false,
            },

            { accessor: "Actions", Header: "Actions" },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by Sub specialties name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          className: "organization-table system-configurations-table",
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          filterList,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
      <QrCode data={selectedRow?.rowData} />
    </Box>
  );
};

export default Patients;
