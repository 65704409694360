import React, { useEffect, useState } from "react";
import { Box, Stack, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";

// import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import SideNavbar from "./SideNavbar";
import Header from "./Header";

const Layout = ({ title }) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (!Cookies.get("Token")) window.location.replace("/login");
    if (!Cookies.get("Token")) window.location.replace("/login");

  }, []);
  return (
    <Stack
      width="100%"
      flexDirection="row"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <Box width={!expanded ? "0" : "20%"}>
        <SideNavbar setExpanded={setExpanded} expanded={expanded} />
      </Box>
      <VStack
        width={{ base: "100%", md: !expanded ? "100%" : "80%" }}
        alignItems="start"
        height="100%"
      >
        <Box
          sx={{ boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)" }}
          w="100%"
          p={1}
        >
          {" "}
          <Header title={title} />
        </Box>

        <Stack p={10} width="100%">
          <Outlet />
        </Stack>
      </VStack>
    </Stack>
  );
};

export default Layout;
