const Types = {
  //! AREAS
  GET_AREAS_SUCCESS: "GET_AREAS_SUCCESS",
  GET_AREA_DETAILS_SUCCESS: "GET_AREA_DETAILS_SUCCESS",
  AREAS_LOADING: "AREAS_LOADING",
  DELETE_AREA_SUCCESS: "DELETE_AREA_SUCCESS",
  UPDATE_AREA_SUCCESS: "UPDATE_AREA_SUCCESS",
  CREATE_AREA_SUCCESS: "CREATE_AREA_SUCCESS",
};

export default Types;
