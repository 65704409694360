/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  subspecialties: [],
  subspecialty: {},
  count: 0,
  load: null,
};

export function subspecialtiesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.SUBSPECIALTIES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_SUBSPECIALTIES_SUCCESS: {
      return {
        ...state,
        subspecialties: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.DELETE_SUBSPECIALTY_SUCCESS: {
      const id = payload?.data;
      const newData = state.subspecialties?.filter((data) => data?.id != id);
      return {
        ...state,
        subspecialties: newData,
      };
    }
    case Types.CREATE_SUBSPECIALTY_SUCCESS: {
      return {
        ...state,
        subspecialties: [...state?.subspecialties, payload?.data],
      };
    }

    case Types.GET_SUBSPECIALTY_DETAILS_SUCCESS: {
      return {
        ...state,
        subspecialty: payload?.data,
      };
    }
    case Types.UPDATE_SUBSPECIALTY_SUCCESS: {
      console.log(payload);
      const newData = payload?.data;
      let newState = state.subspecialties;
      let dataIndx;
      state.subspecialties.map((data, index) => {
        if (data?.id == newData?.id) dataIndx = index;
      });
      newState[dataIndx] = newData;
      return {
        ...state,
        subspecialties: newState,
      };
    }

    default: {
      return state;
    }
  }
}
