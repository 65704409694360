import {
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOpenDoctorDetailsgModel } from "../../../../services/modules/modal/Actions";

const DoctorData = ({ data }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.models.doctorDetails);
  const onClose = () => {
    dispatch(isOpenDoctorDetailsgModel());
  };
  console.log(data);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDir="column"
        >
          <Image src={data?.personal_photo} width="100px" />

          <Text>{data?.full_name}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody gap={5} display="flex" flexDirection="column">
          <HStack>
            <Text>Following count : {data?.following_count}</Text>
          </HStack>
          <HStack>
            <Text>Followers count : {data?.followers_count}</Text>
          </HStack>
          <HStack>
            <Text>Viewers Count : {data?.viewers_count}</Text>
          </HStack>
          <HStack>
            <Text>Phone Number : {data?.phone}</Text>
          </HStack>
          <HStack>
            <Text>Information: {data?.doctor_details?.doctor_information}</Text>
          </HStack>
          <HStack>
            <Text>
              Doctor Title: {data?.doctor_details?.doctor_title?.title_ar}
            </Text>
          </HStack>
          <HStack>
            <Text>
              Doctor Title: {data?.doctor_details?.specialty?.name_ar}
            </Text>
          </HStack>
          <HStack>
            <Text>syndicate card : </Text>
            <Image
              src={data?.doctor_details?.syndicate_card}
              width="100px"
              _hover={{
                transform: "scale(5)",
              }}
            />{" "}
          </HStack>
          <HStack>
            <Text>pp certification : </Text>
            <Image
              src={data?.doctor_details?.pp_certification}
              width="100px"
              _hover={{
                transform: "scale(5)",
              }}
            />{" "}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DoctorData;
