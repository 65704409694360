import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { isOpenDoctorQrCode } from "../../../../services/modules/modal/Actions";
import Logo from "../../../../assets/logo.png";

const DoctorDetails = ({ data }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.models.openDoctorQrCode);
  const onClose = () => {
    dispatch(isOpenDoctorQrCode());
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDir="column"
        >
          <Image src={Logo} width="100px" />

          <Text>{data?.full_name}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={data?.id || "1234"}
            viewBox={`0 0 256 256`}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DoctorDetails;
