/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  doctors: [],
  suspendedDoctors: [],
  unapprovedDoctors: [],
  unapprovedVetDoctors: [],
  doctor: {},
  count: 0,
  load: null,
};

export function doctorsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.DOCTORS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_DOCTORS_SUCCESS: {
      return {
        ...state,
        doctors: payload?.data,
        count: payload?.data?.length,
      };
    }
    case Types.GET_UNAPPROVED_DOCTORS_SUCCESS: {
      return {
        ...state,
        unapprovedDoctors: payload?.data,
        count: payload?.data?.length,
      };
    }
    case Types.GET_UNAPPROVED_VET_DOCTORS_SUCCESS: {
      return {
        ...state,
        unapprovedVetDoctors: payload?.data,
        count: payload?.data?.length,
      };
    }
    case Types.GET_SUSPENDED_DOCTORS_SUCCESS: {
      return {
        ...state,
        suspendedDoctors: payload?.data,
        count: payload?.data?.length,
      };
    }

    case Types.SUSPEND_DOCTOR_SUCCESS: {
      const id = payload?.data;
      const newData = state.doctors?.filter((data) => data?.id != id);
      return {
        ...state,
        doctors: newData,
      };
    }
    case Types.APPROVE_DOCTOR_SUCCESS: {
      const id = payload?.data;
      const newData = state.unapprovedDoctors?.filter((data) => data?.id != id);
      return {
        ...state,
        unapprovedDoctors: newData,
      };
    }
    case Types.GET_DOCTOR_DETAILS_SUCCESS: {
      return {
        ...state,
        doctor: payload.data,
      };
    }

    case Types.GET_SUBSPECIALTY_DETAILS_SUCCESS: {
      return {
        ...state,
        subspecialty: payload?.data,
      };
    }
    case Types.UPDATE_SUBSPECIALTY_SUCCESS: {
      console.log(payload);
      const newData = payload?.data;
      let newState = state.subspecialties;
      let dataIndx;
      state.subspecialties.map((data, index) => {
        if (data?.id == newData?.id) dataIndx = index;
      });
      newState[dataIndx] = newData;
      return {
        ...state,
        subspecialties: newState,
      };
    }

    default: {
      return state;
    }
  }
}
