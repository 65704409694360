import React, { useState } from "react";
import CCrud from "../../components/molecules/crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { Box } from "@chakra-ui/react";
import {
  createAreaRequest,
  deleteAreaRequest,
  getAreasRequest,
  updateAreaRequest,
} from "../../services/modules/cities/areas";
import { getCitiesRequest } from "../../services/modules/cities/cities";
import { toast } from "react-toastify";

const Areas = ({ paginationPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const list = useSelector((state) => state.areas.areas);
  const count = useSelector((state) => state.areas.count);
  const cities = useSelector((state) => state.cities.cities);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [, setPage] = useState(1);
  const [, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getList = async (id) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
      },
      id,
    };
    dispatch(getAreasRequest(requestData));
  };

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const handelSubmit = (data, formik) => {
    // const { name, nameAr, isFulfillmentCenter, fulfillmentCenter, fees } = data;
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/areas");
      !id && formik?.resetForm();
      toast.success(
        ` ${id ? "Areas" : "New Areas"} has been ${
          id ? "updated" : "created"
        } successfully`
      );
    };
    const requestData = {
      body: {
        ...data,
        city_id: data?.city_id?.value,
      },
      id: id,
      action,
    };
    if (id) {
      //update call
      delete requestData?.body?.Actions;

      dispatch(updateAreaRequest(requestData));
    } else {
      //create call
      dispatch(createAreaRequest(requestData));
    }
  };
  const filterList = [
    {
      name: "city_name",
      label: "City",
      type: "select",
      onChange: (value) => {
        console.log(value);
        getList(value?.value);
      },
      options: cities,
    },
  ];

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          dispatch(
            deleteAreaRequest({
              id: data?.id,
            })
          );
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    dispatch(getCitiesRequest());
  }, []);

  const detailsTemplateData = {
    pageTitle: "Areas Details",
    data: {
      list: [],
    },
  };

  return (
    <Box w="100%">
      <CCrud
        table={{
          list: list,
          listTitle: "Areas",
          createBtnTitle: "New Areas",
          CreateBtnIcon: IoIosAdd,
          createBtnFun: () => navigate("?type=form"),
          columns: [
            { accessor: "id", Header: "#" },
            {
              accessor: "area_name",
              Header: "Area name",
              sort: true,
            },
            {
              accessor: "name_ar",
              Header: "Area Arabic name",
              sort: false,
            },
            { accessor: "Actions", Header: "Actions" },
          ],
          isSearch: false,
          searchInputPlaceholder: "Search by Areas name",
          isChecked: false,
          total_count: count,
          isSort: true,
          setFilterObj,
          className: "organization-table system-configurations-table",
          paginationPage: paginationPage,
          handlePageChange,
          rowData: rowData,
          filterList,
          className: "main-table",
          actionsList, //if you want to add custom actions list
          headerChildren,
          openDeleteModal,
          setOpenDeleteModal,
          // handleDeleteRequest,
        }}
        form={{
          initialValues: !id ? {} : initialValues(selectedRow?.rowData),
          structure: () => structure(cities, id),
          validationSchema: validationSchema,
          title: id ? "Edit Areas" : "Add Areas",
          submitTitle: id ? "Update" : "Add",
          onSubmit: handelSubmit,
          className: { form: "main-form", actionButton: "main-form-buttons" },
          // formChildren,
        }}
        // DetailsComponent={<Details />} //to send details component diff from the default
        modalComponents={modalComponents}
      />
    </Box>
  );
};

export default Areas;
