import { reduxRequest } from "../../../common/utils/reduxRequest";
import {
  ApproveDoctorSuccess,
  DoctorsLoading,
  GetDoctorsSuccess,
  GetSuspendedDoctorsSuccess,
  GetUnapprovedDoctorsSuccess,
  GetUnapprovedVetDoctorsSuccess,
  SuspendDoctorSuccess,
} from "./Action";

export const getUnApprovedDoctorsRequest = () => {
  const requestData = {
    isLoading: DoctorsLoading,
    loadingType: "listUnApprove",
    successFunction: GetUnapprovedDoctorsSuccess,
    url: `/admin/doctors/unapproved`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getUnApprovedVetDoctorsRequest = () => {
  const requestData = {
    isLoading: DoctorsLoading,
    loadingType: "listUnApprove",
    successFunction: GetUnapprovedVetDoctorsSuccess,
    url: `/admin/doctors/unapproved_vet`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getSuspendedDoctorsRequest = () => {
  const requestData = {
    isLoading: DoctorsLoading,
    loadingType: "listSuspend",
    successFunction: GetSuspendedDoctorsSuccess,
    url: `/admin/doctors/suspended`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getDoctorsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DoctorsLoading,
    loadingType: "list",
    successFunction: GetDoctorsSuccess,
    url: `/users/doctors`,
    method: "get",
    data: variables?.params,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const approveDoctorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DoctorsLoading,
    successFunction: ApproveDoctorSuccess,
    loadingType: "approve",
    action: variables?.action,
    url: `/admin/doctors/${variables?.id}`,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getDoctorDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DoctorsLoading,
    loadingType: "get",
    successFunction: GetDoctorsSuccess,
    action: variables?.action,
    url: `admin/sub_specialties/${variables?.id}`,
    method: "get",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const suspendDoctorRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: DoctorsLoading,
    successFunction: SuspendDoctorSuccess,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/doctors/${variables?.id}`,
    method: "delete",
    data: variables?.id,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
